import TextField from '../fields/TextField';
import Typography from '../Typography';
import Box from '../Box';
import EmailField from '@/components/fields/EmailField';
import { useMemo } from 'react';
import OptionsField from '@/components/fields/OptionsField';
import * as Formatter from '@/utils/FormatterUtil.js';
import PhoneField from '@/components/fields/PhoneField';
import Select from '@/components/Select';

const InvestorForm = ({ form, onSubmit, presets, turnstile_sitekey, ...properties }) => {
    const options = {
        salutations: presets.salutations.map((salutation) => ({
            title: Formatter.salutation(salutation),
            value: salutation,
        })),
        investable_assets: [
            {
                title: '€100k tot €150k',
                value: 100000,
            },
            {
                title: '€150k tot €300k',
                value: 150000,
            },
            {
                title: '€300k tot €500k',
                value: 300000,
            },
            {
                title: '€500k+',
                value: 500000,
            },
        ],
    };
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (key, event) => {
        const target = event.target;
        const { name, value } = target;

        if (key) {
            form.setData({ ...data, [key]: { ...data?.[key], [name]: value } });
        } else {
            form.setData({ ...data, [name]: value });
        }
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Belegbaar vermogen*</Typography>

                <OptionsField name={'investable_assets'} value={data?.investor?.investable_assets} error={errors?.investor?.investable_assets} options={options.investable_assets} onChange={(event) => handleFormChange('investor', event)} />

                {errors?.investor?.investable_assets && <Typography color={'error.base'}>{errors.investor.investable_assets}</Typography>}
            </div>

            <Box mt={24} display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Aanhef*</Typography>

                    <Select name={'salutation'} value={data?.user?.salutation} error={errors?.user?.salutation} options={options.salutations} onChange={(event) => handleFormChange('user', event)} />

                    {errors?.user?.salutation && <Typography color={'error.base'}>{errors.user.salutation}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Voornaam*</Typography>

                    <TextField name={'first_name'} type={'text'} value={data?.user?.first_name} error={errors?.user?.first_name} onChange={(event) => handleFormChange('user', event)} />

                    {errors?.user?.first_name && <Typography color={'error.base'}>{errors.user.first_name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Achternaam*</Typography>

                    <TextField name={'last_name'} type={'text'} value={data?.user?.last_name} error={errors?.user?.last_name} onChange={(event) => handleFormChange('user', event)} />

                    {errors?.user?.last_name && <Typography color={'error.base'}>{errors.user.last_name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>E-mailadres*</Typography>

                    <EmailField name={'email'} value={data?.user?.email} error={errors?.user?.email} onChange={(event) => handleFormChange('user', event)} />

                    {errors?.user?.email && <Typography color={'error.base'}>{errors.user.email}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Telefoonnummer*</Typography>

                    <PhoneField name={'phone'} value={data?.user?.phone} onChange={(event) => handleFormChange('user', event)} />

                    {errors?.user?.phone && <Typography color={'error.base'}>{errors.user.phone}</Typography>}
                </div>
            </Box>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default InvestorForm;
